<template>
  <div class="common-bg">
    <van-nav-bar
      title="银行卡"
      left-arrow
      :fixed="true"
      :placeholder="true"
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="from.bankCard"
        name="bankCard"
        type="number"
        label="银行卡号"
        clearable
        placeholder="请输入银行卡号"
        :rules="[{ required: true, message: '请输入银行卡号' }]"
      />
      <van-field
        v-model="from.bank"
        name="bank"
        label="开户地址"
        clearable
        placeholder="请输入完整的开户地址"
        :rules="[{ required: true, message: '请输入完整的开户地址' }]"
      />
		<van-field
		v-model="from.realName"
		name="realName"
		label="开户姓名"
		clearable
		placeholder="请输入完整的开户姓名"
		:rules="[{ required: true, message: '请输入完整的开户姓名' }]"
		/>
      <div style="margin: 16px">
        <van-button round block type="warning" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { getUserInfo, bindBankCard } from '@/utils/api.js'
export default {
  name: 'bankCard',
  data() {
    return {
      from: {
        bankCard: '',
        bank: '',
		   realName:''
      }
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code === 200) {
          this.from.bankCard = res.data.user.bankCard
          this.from.bank = res.data.user.bank
		  this.from.realName = res.data.user.realName
        }
      })
    },
    onSubmit() {
      bindBankCard(this.from).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.message)
          setTimeout(() => {
            this.$toast.clear()
            this.onClickLeft()
          }, 1500)
        } else {
          this.$toast.fail(res.message)
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped></style>
